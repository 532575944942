import React, { useState } from "react";

const Footer = ({ content }) => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false); // State to track form submission
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(null); // State for email validation error

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email format
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError(null);
    }

    // Form ID and entry ID for the email field
    const formId = "1FAIpQLSe04qeCZ_iZhTuHKYkyheFrFIS6aKMujbaLHdhrk23P5pvSnA";

    // Construct the Google Forms endpoint
    const endpoint = `https://docs.google.com/forms/d/e/${formId}/formResponse`;

    // Prepare form data
    const formData = new FormData();
    formData.append("entry.470813199", email); // Replace with the actual entry ID for the email field

    try {
      setSubmitting(true);
      await fetch(endpoint, {
        method: "POST",
        body: formData,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
      setEmail("");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <footer className="bg-white py-8 text-center px-16">
      <div className="container max-w-7xl mx-auto text-center flex flex-col gap-8">
        <h2 className="text-2xl text-zinc-800 font-outfitSemiBold">
          {content.title}
        </h2>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full items-center"
        >
          <input
            type="email"
            placeholder={content.placeholder}
            className={`w-full max-w-md p-2 border border-zinc-300 rounded-lg mb-4 ${
              emailError ? "border-red-500" : ""
            }`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null); // Clear error when user types
            }}
            disabled={submitting || submitted} // Disable input field while submitting or after submission
          />
          {emailError && <p className="text-red-500">{emailError}</p>}
          <button
            type="submit"
            className={`w-full max-w-md bg-slate-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-slate-600 ${
              submitting || submitted ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={submitting || submitted || !email || !!emailError} // Disable button based on form state and validation
          >
            {submitting
              ? "Loading..."
              : submitted
              ? "Thanks for subscribing!"
              : content.button}
          </button>
        </form>
        <p>{content.copyright}</p>
      </div>
    </footer>
  );
};

export default Footer;
