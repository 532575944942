import React from "react";
import "./App.css";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import content from "./content/medical-clinic.json";
import image from "./assets/talos-image.jpg";

function App() {
  const {
    navigationBar,
    hero,
    features,
    testimonials,
    footer,
    customerLogos,
    sections,
  } = content;

  return (
    <div>
      <NavigationBar
        title={navigationBar.title}
        button={navigationBar.button}
        url={navigationBar.url}
      />

      <section className="bg-zinc-100 px-16 py-16 flex items-center">
        <div className="max-w-7xl container mx-auto flex flex-col md:flex-row items-center gap-16">
          <div className="md:w-1/2">
            <h1 className="text-5xl text-zinc-800 mb-4 font-outfitSemiBold">
              {hero.title}
            </h1>
            <p className="text-zinc-600 mb-6">{hero.subtitle}</p>
            <a
              href={hero.url}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-slate-900 text-white font-bold py-3 px-4 rounded-lg hover:bg-slate-600"
            >
              {hero.button}
            </a>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img
              src={image}
              alt={hero.imageAlt} // Dynamic alt text from JSON
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      </section>

      <section className="bg-zinc-100 px-16 py-8 flex flex-col items-center justify-center">
        <p className="text-zinc-600 text-center mb-8">
          {sections.customerLogos.text}
        </p>
        {customerLogos.map((logo, index) => (
          <img
            key={index}
            className="w-48 grayscale"
            src={logo.url}
            alt={`${logo.name} logo`}
          />
        ))}
      </section>

      <section className="bg-white py-16 px-16">
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-4xl font-outfitSemiBold text-zinc-800 mb-12">
            {sections.whyTalos.title}
          </h2>
          <p className="text-zinc-600 text-center mb-8">
            {sections.whyTalos.description}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="p-6 rounded-xl border-2 bg-white">
                <i
                  className={`fi w-10 h-10 text-yellow-500 text-4xl flex items-center justify-center ${feature.icon}`}
                  aria-hidden="true"
                />
                <h3 className="text-2xl font-bold my-2 mt-3">
                  {feature.title}
                </h3>
                <p className="text-zinc-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-zinc-100 py-16 px-16">
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-4xl text-center text-zinc-800 mb-8 font-outfitSemiBold">
            {sections.testimonials.title}
          </h2>
          <p className="text-zinc-600 text-center mb-8">
            {sections.testimonials.description}
          </p>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-lg"
            >
              <p className="text-zinc-600 mb-4">"{testimonial.quote}"</p>
              <a
                href={testimonial.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-900 hover:underline mt-4 block w-fit"
              >
                - {testimonial.author}
              </a>
            </div>
          ))}
        </div>
      </section>

      <Footer content={footer} />
    </div>
  );
}

export default App;
